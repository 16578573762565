exports = module.exports = require("../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._24Mto-yB6YmRUwYOEW8_jI {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n", ""]);

// exports
exports.locals = {
	"fixedTable": "_24Mto-yB6YmRUwYOEW8_jI"
};